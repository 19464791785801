import axios from "axios";

export default class DeleteAccountRepository {

    async deleteAccount(email) {
        await axios.post(
            `https://rqckalzlqcvoacucvjlj.supabase.co/functions/v1/api/users/delete`,
            {
                email: email
            },
            {
                headers: {}
            }
        );
    }
}
