import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import DeleteAccountRepository from './repository/DeleteAccountRepository';

function App() {

    const [loadingText, setLoadingText] = useState(null)
    const [isCompleted, setIsCompleted] = useState(false)
    const [email, setEmail] = useState(null)
    const deleteAccountRepository = new DeleteAccountRepository()

    async function deleteAccount() {
        setLoadingText("Sending ...")
        try {
        await deleteAccountRepository.deleteAccount()
        } catch(e) {

        }
        setLoadingText(null)
        setIsCompleted(true)
    }

    function onEmailChange(event) {
        console.log(`description input change ${event.target.value}`)
        setEmail(event.target.value)
    }

    return <div className='flex flex-col h-screen'>
        <div className="navbar bg-base-100">
                <div className="flex-1 pl-2">
                <div className="">
                    <img className="h-8" src="https://elasticbeanstalk-us-east-2-366096891162.s3.us-east-2.amazonaws.com/baya-logo-horizontal-small-4.png"/>
                </div>
                </div>
        </div>
        <div className='grow bg-base-100 flex flex-col place-content-center overflow-y-hidden px-4'>
            <div className="pt-2 text-base text-base-content w-full text-center self-center max-w-lg">Submit Google account below to send a request to delete your Roambear account. Our team will contact you via email shortly after.</div>
            <input type="text" placeholder="Type here" className="mt-2 input input-bordered w-full text-center self-center max-w-lg" onChange={onEmailChange}/>
            <div className='self-center pt-4'>
                <button className="btn btn-primary normal-case font-bold group ml-2" onClick={() => {
                    deleteAccount()
                }}>Request to delete account</button>
            </div>
            
        </div>
        {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{loadingText}</div>
            </div>
            }
            {isCompleted && <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/50 px-4'>
                <div className='font-normal text-base-content mt-2'>Request sent. Our team will contact you via email within the next few days!</div>
            </div>
            }
        </div>;
}

export default App;
